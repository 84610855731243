




































import { Vue, Component } from 'vue-property-decorator';
import { IntegrationsService } from '@/lib/services/integrations';
import { unwrapError } from '@/lib/helpers';
import { isPast } from 'date-fns';
import { Integration } from '@/models';
import IntegrationConfigModal from '@/components/integration/IntegrationConfigModal.vue';

@Component({
  components: {
    IntegrationConfigModal,
  },
})
export default class IntegrationCallbackSettingsPage extends Vue {
  provider: string = '';
  options: any = {};
  status: string = 'loading';
  error: string = '';

  service: IntegrationsService | null = null;
  integration: Integration | null = null;
  saved: boolean = false;
  isPopup: boolean = false;
  originalPath: string = '';

  async created(): Promise<void> {
    this.provider = this.$route.params['provider'] || '';
    this.options = this.$route.query;

    const connInfo = JSON.parse(
      localStorage.getItem('oauth-connect-' + this.provider) || '',
    );
    if (!connInfo) {
      this.status = 'failed';
      this.error = 'Oauth connection info unavailable';
      return;
    }

    if (connInfo['expires'] && isPast(connInfo['expires'])) {
      this.status = 'failed';
      this.error = 'Oauth connection expired. Try again.';
      return;
    }

    this.isPopup = !!connInfo['popup'];
    this.originalPath = connInfo['currentRoute'];
    const isForAccountant = !!connInfo['accountant'];
    this.service = new IntegrationsService(isForAccountant);
    try {
      await this.service.callback(this.provider, this.options);
      this.status = 'success';

      if (connInfo.openConfig) {
        this.integration = await this.service.get(this.provider);
      }
    } catch (e) {
      this.status = 'failed';
      this.error = unwrapError(e);
    }
  }

  async onSaveAndContinue(props: Record<string, unknown>): Promise<void> {
    await this.onSave(props);
    this.$nextTick(() => (this.saved = false));
  }

  async onSave(props: Record<string, unknown>): Promise<void> {
    if (!this.service) return;

    try {
      await this.service.update(this.provider, props);
      this.$toaster.success('Integration updated');
      this.saved = true;

      // Kind of the same logic as without openConfig
      if (this.isPopup) {
        this.close();
      } else if (this.originalPath) {
        window.location.href = this.originalPath;
      }
    } catch (e) {
      this.$toaster.error('Could not update integration', unwrapError(e));
    }
  }

  close(): void {
    window.self.close();
  }
}
